/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

import UTeaserView from '@package/components/manage/Blocks/UTeaser/View';
import UTeaserEdit from '@package/components/manage/Blocks/UTeaser/Edit';
import UTeaserBlockDefaultBody from '@package/components/manage/Blocks/UTeaser/DefaultBody';
import { TeaserSchema as UTeaserSchema } from '@package/components/manage/Blocks/UTeaser/schema';
import { TeaserBlockDataAdapter as UTeaserBlockDataAdapter } from '@package/components/manage/Blocks/UTeaser/adapter';

import UTextTeaserView from '@package/components/manage/Blocks/UTextTeaser/View';
import UTextTeaserEdit from '@package/components/manage/Blocks/UTextTeaser/Edit';
import UTextTeaserBlockDefaultBody from '@package/components/manage/Blocks/UTextTeaser/DefaultBody';
import { TeaserSchema as UTextTeaserSchema } from '@package/components/manage/Blocks/UTextTeaser/schema';
import { TeaserBlockDataAdapter as UTextTeaserBlockDataAdapter } from '@package/components/manage/Blocks/UTextTeaser/adapter';

import UStickerView from '@package/components/manage/Blocks/USticker/View';
import UStickerEdit from '@package/components/manage/Blocks/USticker/Edit';
import UStickerBlockDefaultBody from '@package/components/manage/Blocks/USticker/DefaultBody';
import { TeaserSchema as UStickerSchema } from '@package/components/manage/Blocks/USticker/schema';
import { TeaserBlockDataAdapter as UStickerBlockDataAdapter } from '@package/components/manage/Blocks/USticker/adapter';

import imagesSVG from '@plone/volto/icons/images.svg';

import UVideoView from '@package/components/manage/Blocks/UVideo/View';
import UVideoEdit from '@package/components/manage/Blocks/UVideo/Edit';

import UImageView from '@package/components/manage/Blocks/UImage/View';
import UImageEdit from '@package/components/manage/Blocks/UImage/Edit';

import Matomo from '@package/components/manage/Matomo/Matomo';

import ImageSettingsSchema from '@plone/volto/components/manage/Blocks/Image/LayoutSchema';
import { getImageBlockSizes } from '@plone/volto/components/manage/Blocks/Image/utils';

import { imageBlockSchemaEnhancer } from '@package/components/manage/Blocks/UImage/schema';
import { ImageBlockDataAdapter } from '@package/components/manage/Blocks/UImage/adapter';
import { gridImageDisableSizeAndPositionHandlersSchema } from '@plone/volto/components/manage/Blocks/Image/schema';
import { composeSchema } from '@plone/volto/helpers';
import { defineMessages } from 'react-intl';

const CONTENT_COLORS = [
  { name: 'highlight-custom-color-1', label: 'Blue' },
  { name: 'highlight-custom-color-2', label: 'Pink' },
  { name: 'highlight-custom-color-3', label: 'Orange' },
  { name: 'highlight-custom-color-4', label: 'Light-green' },
  { name: 'highlight-custom-color-5', label: 'Light-purple' },
  { name: 'highlight-custom-color-6', label: 'Light-grey' },
  { name: 'highlight-custom-color-7', label: 'Dark' },
  { name: 'highlight-custom-color-8', label: 'Transparent' },
];

defineMessages({
  Overview: {
    id: 'Overview',
    defaultMessage: 'Overview',
  },
});

export default function applyConfig(config) {
  config.blocks.blocksConfig.uteaser = {
    id: 'uteaser',
    title: 'UTeaser',
    icon: imagesSVG,
    group: 'common',
    view: UTeaserView,
    edit: UTeaserEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    blockSchema: UTeaserSchema,
    dataAdapter: UTeaserBlockDataAdapter,
    variations: [
      {
        id: 'default',
        isDefault: true,
        title: 'Default',
        template: UTeaserBlockDefaultBody,
      },
    ],
  };

  config.blocks.blocksConfig.utextteaser = {
    id: 'utextteaser',
    title: 'UTxtTeaser',
    icon: imagesSVG,
    group: 'common',
    view: UTextTeaserView,
    edit: UTextTeaserEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    blockSchema: UTextTeaserSchema,
    dataAdapter: UTextTeaserBlockDataAdapter,
    variations: [
      {
        id: 'default',
        isDefault: true,
        title: 'Default',
        template: UTextTeaserBlockDefaultBody,
      },
    ],
  };

  config.blocks.blocksConfig.usticker = {
    id: 'usticker',
    title: 'USticker',
    icon: imagesSVG,
    group: 'common',
    view: UStickerView,
    edit: UStickerEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    blockSchema: UStickerSchema,
    dataAdapter: UStickerBlockDataAdapter,
    descriptionColors: CONTENT_COLORS,
    variations: [
      {
        id: 'default',
        isDefault: true,
        title: 'Default',
        template: UStickerBlockDefaultBody,
      },
    ],
  };

  config.blocks.blocksConfig.uvideo = {
    id: 'uvideo',
    title: 'UVideo',
    icon: imagesSVG,
    group: 'common',
    view: UVideoView,
    edit: UVideoEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.uimage = {
    id: 'uimage',
    title: 'UImage',
    icon: imagesSVG,
    group: 'media',
    view: UImageView,
    edit: UImageEdit,
    schema: ImageSettingsSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    getSizes: getImageBlockSizes,
    schemaEnhancer: imageBlockSchemaEnhancer,
    dataAdapter: ImageBlockDataAdapter,
  };

  config.blocks.blocksConfig.gridBlock.blocksConfig.uimage = {
    ...config.blocks.blocksConfig.uimage,
  };

  config.blocks.blocksConfig.gridBlock.blocksConfig.uimage.schemaEnhancer =
    composeSchema(
      imageBlockSchemaEnhancer,
      gridImageDisableSizeAndPositionHandlersSchema,
    );
  config.blocks.blocksConfig.gridBlock.blocksConfig.uimage.dataAdapter =
    ImageBlockDataAdapter;

  config.settings.isMultilingual = true;
  config.settings.supportedLanguages = ['fi', 'en'];
  config.settings.defaultLanguage = 'fi';

  config.blocks.blocksConfig.highlight = {
    ...config.blocks.blocksConfig.highlight,
    descriptionColors: CONTENT_COLORS,
  };

  //console.log(config.blocks.blocksConfig.gridBlock);

  config.blocks.blocksConfig.gridBlock.allowedBlocks = [
    'image',
    'listing',
    'slate',
    'teaser',
    'uteaser',
    'utextteaser',
    'usticker',
  ];

  // Fix the grid block having its own blocksconfig

  config.blocks.blocksConfig.gridBlock.blocksConfig.uteaser =
    config.blocks.blocksConfig.uteaser;

  config.blocks.blocksConfig.gridBlock.blocksConfig.utextteaser =
    config.blocks.blocksConfig.utextteaser;

  config.blocks.blocksConfig.gridBlock.blocksConfig.usticker =
    config.blocks.blocksConfig.usticker;

  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: '',
      component: Matomo,
    },
  ];

  return config;
}
